<template>
  <b-modal v-if="group" :id="modalId" >
    <template v-slot:modal-title>
      <div class="w-color-primary m-0 pl-1">
        Naozaj chcete zduplikovať skupinu "{{ group.name }}"?
      </div>
    </template>
    
    <template v-slot:modal-header-close>
      <b-link class="a-icon-wrapper border-0 a-color-primary" href="#" @click="close()">
        <img src="@/plugins/app/_theme/icon/close.svg" />
      </b-link>
    </template>
    
    <template v-slot:modal-footer>
      <b-button class="button mt-3" variant="outline-primary" @click="create">Potvrdiť</b-button>
    </template>
  </b-modal>
</template>

<script>
import wAxios from '@/plugins/w/axios'

const api = {
  duplicate: (id) => wAxios.post_auth(`/v1/group-admin/groups/${id}/duplicate`),
}

export default {
  props: {
    group: { required: true },
    modalId: { required: true }
  },

  methods: {
    async create() {
      if (!this.group.id) {
        return
      }

      try {
        await api.duplicate(this.group.id)
        this.$bvToast.toast(
          'Skupina bola úspešne zduplikovaná!', 
          {
            title: 'Podarilo sa!',
            autoHideDelay: 3000,
            variant: 'a-success',
            toaster: 'b-toaster-top-center',
            solid: true
          }
        )
        this.$emit('createdGroup')
        this.close()
      } catch(err) {
        this.$wToast.error(err)
      }
    },

    close() {
      this.$bvModal.hide(this.modalId)
    }
  }
}
</script>

<style lang="sass" scoped>
/deep/ .modal-footer
  border-top: 0
</style>
